<template>
  <div class="container">
    <el-descriptions title="用户信息" :column="1">
      <el-descriptions-item label="用户名">{{
        info.name
      }}</el-descriptions-item>
      <el-descriptions-item label="身份">{{
        info.short_name || info.type
      }}</el-descriptions-item>
      <el-descriptions-item label="电话">{{
        info.mobile
      }}</el-descriptions-item>
      <el-descriptions-item label="绑定用户账号">
        <div class="flex al">
          {{ info.user_id > 0 ? "已绑定" : "未绑定" }}
          <div class="text-btn" @click="openUser">编辑</div>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="绑定收益账户">
        <div class="flex al">
          <span> {{ form.aliPay }}</span>
          <div class="text-btn" @click="openAccount">编辑</div>
        </div>
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      title="收益信息"
      v-if="info.is_employees"
      :column="1"
      style="margin-top: 30px"
    >
      <el-descriptions-item label="收益分成">
        <div class="flex al">
          ￥<span style="font-weight: bold"> {{ info.bonus }}</span>
          <div class="text-btn" @click="cashShow = true">提现</div>
          <div class="text-btn" @click="toDetails">详情</div>
        </div>
      </el-descriptions-item>
    </el-descriptions>

    <el-dialog title="用户账号" :visible.sync="userShow" width="50%">
      <div class="flex al m-b10" v-if="userInfo && userInfo.user_id">
        <img :src="userInfo.avatar" alt="" class="ava" />
        <div>
          <div>{{userInfo.nick_name}}</div>
         <div class="mobile">{{userInfo.mobile}}</div>
        </div>
      </div>
      <el-form :inline="true">
        <el-form-item label="账号电话">
          <el-input v-model="phone"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="getUserList(1)">搜索</el-button>
        </el-form-item>
      </el-form>
      <auto-table
        :DataList="DataList"
        :option="Option"
        :total="Total"
        @changePage="getUserList"
      >
        <template #avatar="{ row }">
          <div>
             <img :src="row.avatar" alt="" class="ava" />
          </div>
        </template>
        <template #handler="{ row }">
          <div>
            <el-button type="primary" @click="bindUser(row)">绑定</el-button>
          </div>
        </template>
      </auto-table>
    </el-dialog>

    <el-dialog
      title="提现账户"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="账户姓名">
          <el-input v-model="aliform.name"></el-input>
        </el-form-item>
        <el-form-item label="支付宝账号">
          <el-input v-model="aliform.aliPay"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="savePayAccount">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="余额提现"
      :visible.sync="cashShow"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="余额">
          <div>{{ info.bonus }}</div>
        </el-form-item>
        <el-form-item label="提现金额">
          <el-input
            type="number"
            :min="0"
            v-model="transferForm.cash"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="提现说明">
          <el-input v-model="transferForm.remark"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cashShow = false">取 消</el-button>
        <el-button type="primary" @click="aliTransfer" v-loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      cashShow: false,
      admin_id: JSON.parse(localStorage.getItem("managerInfo")).id,
      info: {},
      form: {},
      aliform: {},
      transferForm: {},
      loading: false,
      phone: "",
      userShow: false,
      DataList: [],
      Option: [
         { name: "头像", value: "avatar", type: "custom" },
        { name: "昵称", value: "nick_name" },
         { name: "电话", value: "mobile" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      userInfo:{}
    };
  },

  mounted() {
    this.getAdminInfo();
    this.getPayAoocunt();
  },

  methods: {
    bindUser(user){
        this.$axios({
        url: "/authorization/managerUser/update",
        method:'post',
        data: {
          id: this.admin_id,
          user_id:user.user_id
        },
      }).then((res) => {
        this.userShow = false
       this.$alert('绑定成功，请重新登录').then(()=>{
        this.$store.dispatch("logout");
      this.$router.push("/login");
      this.$tim.logout();
      localStorage.removeItem("timSign");
      this.$store.commit("timLogin", false);
       })
      });
    },
    getUserList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/userInfo/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          mobile: this.phone || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    openUser() {
      if (this.info.user_id > 0) {
         this.$axios({
        url: "/user/userInfo/findById",
        params: {
         id:this.info.user_id
        },
      }).then((res) => {
        this.userInfo = res.data.data;
      });
      }
      this.userShow = true;
    },
    aliTransfer() {
      if (this.transferForm.cash == "" || this.transferForm.cash == 0) {
        this.$message("请填写提现金额");
        return;
      }
      if (this.info.bonus < this.transferForm.cash) {
        this.$message("余额不足");
        return;
      }
      this.loading = true;
      this.$axios({
        url: "/user/pay/aliTransferAdmin",
        method: "post",
        data: {
          admin_id: this.admin_id,
          amount: this.transferForm.cash,
          source: "pc",
          rcharge_abstract: "提现",
          rcharge_type: "transfer",
        },
      })
        .then((res) => {
          this.loading = false;
          this.cashShow = false;
          this.transferForm = {};
          this.$message(res.data.message);
          this.getAdminInfo();
        })
        .catch((err) => {
          this.loading = false;
          this.cashShow = false;
          this.$message(res.data.message);
        });
    },

    getAdminInfo() {
      this.$axios({
        url: "/authorization/managerUser/findById",
        params: {
          id: this.admin_id,
        },
      }).then((res) => {
        this.info = res.data.data;
            localStorage.setItem("managerInfo", JSON.stringify(this.info));
      });
    },
    getPayAoocunt() {
      this.$axios({
        url: "/user/rechargeInfo/queryListPage",
        params: {
          admin_id: this.admin_id,
        },
      }).then((res) => {
        this.form = res.data.data[0] || {};
      });
    },
    toDetails() {
      this.$router.push({
        name: "bonusDetails",
        query: {
          id: this.admin_id,
        },
      });
    },
    openAccount() {
      this.aliform.name = this.form.name || "";
      this.aliform.aliPay = this.form.aliPay || "";
      this.dialogVisible = true;
    },
    savePayAccount() {
      if (this.form.id) {
        this.$axios({
          url: "/user/rechargeInfo/update",
          method: "post",
          data: {
            id: this.form.id,
            name: this.aliform.name,
            aliPay: this.aliform.aliPay,
          },
        }).then((res) => {
          this.getPayAoocunt();
          this.dialogVisible = false;
        });
      } else {
        this.$axios({
          url: "/user/rechargeInfo/insert",
          method: "post",
          data: {
            admin_id: this.admin_id,
            name: this.aliform.name,
            aliPay: this.aliform.aliPay,
          },
        }).then((res) => {
          this.getPayAoocunt();
          this.dialogVisible = false;
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.text-btn {
  color: rgb(68, 124, 230);
  cursor: pointer;
  margin-left: 10px;
}
.ava{
 width: 60px;
  height: 60px;
}
.mobile{
  margin-top: 4px;
  font-size: 12px;
  color: #888;
}
</style>